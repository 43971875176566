<template>
  <div>
    <v-data-table
      :items="quotes"
      :headers="headers"
      :search="search"
      :loading="loading"
      dense
      sort-by="order_number"
      :sort-desc="true"
    >
      <!--Filters-->
      <template v-slot:top>
        <TableSummaryAlert
          :title="'Quotes'"
          :total="quoteTotal"
          :itemCount="quotes.length"
        />
      </template>
      <!--Format total-->
      <template v-slot:item.order_value="{ item }">
        {{ formatAsCurrency("R", item.order_value) }}
      </template>
      <!--Click to view-->
      <template v-slot:item.order_number="{ item }">
        <a @click="viewQuote(item)">{{ item.order_number }}</a>
      </template>
      <!--Empty-->
      <template v-slot:no-data>
        <EmptyTableSlot :type="'Quotes'" />
      </template>
    </v-data-table>
  </div>
</template>
<script>
import db from "../../../../components/firebaseInit";
import { formatAsCurrency } from "../../../../composables/external";
export default {
  name: "CustomerQuotesTable",
  props: ["customer_id"],
  components: {
    TableSummaryAlert: () => import("../Alerts/TableSummaryAlert.vue"),
    EmptyTableSlot: () => import("../Tables/EmptyTableSlot.vue"),
  },
  data() {
    return {
      search: "",
      loading: false,
      quotes: [],
      headers: [
        { text: "QU #", value: "order_number" },
        { text: "Date", value: "order_date" },
        { text: "Total", value: "order_value", align: "right" },
      ],
    };
  },
  computed: {
    quoteTotal() {
      return this.quotes.reduce((total, item) => total + item.order_value, 0);
    },
  },
  created() {
    this.getQuotes();
  },
  methods: {
    formatAsCurrency,
    getQuotes() {
      this.loading = true;
      const docRef = db
        .collection("sales_orders")
        .where("customer.customer_id", "==", this.customer_id)
        .where("order_status", "==", "Quote");
      docRef
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            this.quotes.push(data);
          });
          this.loading = false;
        })
        .catch((error) => error);
    },
    // Click to view quote in new tab
    viewQuote(item) {
      const routeData = this.$router.resolve({
        path: `/view-order/${item.order_id}`,
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>