import firebase from 'firebase'

//  Shorthand firebase timestamp call
export const firebaseTimestamp = function () {
    return firebase.firestore.FieldValue.serverTimestamp()
}

//  Convert Date to ISO format
export const newDateToISO = function () {
    return new Date().toISOString().slice(0, 10)
}

//  Format currency
export const formatAsCurrency = function (currency, inputVal) {
    return `${currency} ${new Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
        .format(inputVal)}`
}

//  An object constructor function that takes an array of strings
//  that represent the properties of a firestore document
//  It is used to make document Adds & Updates less verbose
export const firestoreDocProps = function (arr, type) {
    let docPropObj = {};
    arr.forEach((prop) => {
        docPropObj[prop] = type[prop];
    });
    return docPropObj;
};